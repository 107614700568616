<!-- GET /fms_css/contract/list 分页查询合同信息 -->
<!-- GET /fms_css/contract/listContractLog listLogs -->
<template>
  <div>
    <div>
      <!-- 搜索框 -->
      <a-card style="margin-bottom: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator" style="margin-bottom: -8px">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <!-- 门店编号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店编号:">
                    <a-input
                      v-model="queryParam.facilityCode"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 门店名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店名称:">
                    <a-input
                      v-model="queryParam.facilityName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 档口编号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="档口编号:">
                    <a-input
                      v-model="queryParam.kitchenCode"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 店铺名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="店铺名称:">
                    <a-input
                      v-model="queryParam.storeName"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 合同编号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="合同编号:">
                    <a-input
                      v-model="queryParam.contractCode"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <!-- 是否标准合同 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="是否标准合同:">
                    <a-select
                      style="width: 180px"
                      allowClear
                      v-model="queryParam.isStandard"
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in yesOrNo" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 合同状态 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="合同状态:">
                    <a-select
                      allowClear
                      style="width: 180px"
                      v-model="queryParam.contractStatus"
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in contractStatusList" :key="item.code">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="24" :sm="24" class="fr">
                  <a-form-item>
                    <a-button type="primary" @click="initTable()"
                      >搜索</a-button
                    >
                  </a-form-item>
                  <a-form-item>
                    <a-button @click="resetQueryParam">重置</a-button>
                  </a-form-item>
                  <a-form-item>
                    <a-button @click="onExportContract">合同导出</a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
      </a-card>
      <!-- table -->
      <a-spin :spinning="dataLoading">
        <a-card style="margin-bottom: 24px">
          <a-row :gutter="0" style="line-height: 3">
            <a-table
              rowKey="id"
              :pagination="pagination"
              :columns="columns"
              :dataSource="tableData"
              :scroll="{ x: 800 }"
              @change="changePageSize"
            >
              <span slot="startDateLg" slot-scope="text, record">
                {{ record.startDateLg }} - {{ record.endDateLg }}
              </span>
              <span slot="action" slot-scope="text, record" class="blueColor">
                <a
                  v-if="isCanViewDetail"
                  @click="viewDetail(record)"
                  style="margin-right:0.8em"
                  >查看</a
                >
                <a
                  v-if="isCanViewContractLog"
                  @click="handleActive(record)"
                  style="margin-right:0.8em"
                  >操作日志</a
                >
              </span>
            </a-table>
          </a-row>
        </a-card>
      </a-spin>
      <a-modal
        title="操作日志"
        width="700px"
        v-model="visible"
        :okButtonProps="{ props: { disabled: true } }"
        :cancelButtonProps="{ props: { disabled: true } }"
        footer
        @change="changePageActionSize"
      >
        <a-table
          rowKey="id"
          :pagination="actionPagination"
          :columns="actionColumns"
          :dataSource="actionData"
        >
        </a-table>
      </a-modal>

      <!-- 导出合同列表 -->
      <a-modal
          title="导出合同列表"
          :visible="showExportContractModal"
          :confirmLoading="showExportContractLoading"
          @ok="onConfirmExportContract"
          @cancel="onCancelExportContract"
          destroyOnClose
      >
        <a-row :gutter="30" style="line-height: 4">
          <a-col :md="24" :sm="24">
            <span class="mandatory">*</span>
            <span>选择城市：</span>
            <a-select
                style="width: 175px;margin-left: 0.5em;"
                v-model="exportQuery.cityId"
                placeholder="请选择"
                @change="onChangeExportCity"
            >
              <a-select-option v-for="item in userCitiesAll" :key="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col :md="24" :sm="24">
            <span class="optional"> </span>
            <span>选择门店：</span>
            <a-select
                style="width: 175px;margin-left: 0.5em;"
                v-model="exportQuery.facilityId"
                allowClear
                placeholder="请选择"
            >
              <a-select-option v-for="item in exportAvailableFacility" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col :md="24" :sm="24">
            <span class="mandatory">*</span>
            <span>日期范围：</span>
            <a-date-picker
                class="date-picker"
                format="YYYY-MM-DD"
                v-model="exportQuery.startDate"
                placehholder="选择开始日期"
            />
            <a-date-picker
                class="date-picker"
                format="YYYY-MM-DD"
                v-model="exportQuery.endDate"
                placehholder="选择结束日期"
            />
          </a-col>
        </a-row>
      </a-modal>

    </div>
  </div>
</template>
<script>
  
import axios from "axios";
import moment from "moment";
import { defaultPagination } from "../../../utils/pagination";
import { mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";
import { hasPermissions } from "../../../utils/auth";
import { OrganizationActions } from "../../../utils/actions";
import fileDownload from "js-file-download";
export default {
  name: "contractList",
  data() {
    return {
      // 搜索
      queryParam: {},
      // 分页
      pagination: defaultPagination(() => {}),
      pageSize: 20,
      current: 1,
      // 表头
      columns: [
        {
          title: "合同编号",
          width: 160,
          dataIndex: "contractCode",
          key: "contractCode",
          fixed: "left",
        },
        {
          title: "城市",
          width: 100,
          dataIndex: "cityName",
          key: "cityName"
        },
        {
          title: "厨房品牌",
          width: 100,
          dataIndex: "brandName",
          key: "brandName"
        },
        {
          title: "门店编号",
          width: 100,
          dataIndex: "facilityCode",
          key: "facilityCode"
        },
        {
          title: "门店名称",
          width: 160,
          dataIndex: "facilityName",
          key: "facilityName"
        },
        {
          title: "档口编号",
          width: 100,
          dataIndex: "kitchenCode",
          key: "kitchenCode"
        },
        {
          title: "店铺名称",
          width: 180,
          dataIndex: "storeName",
          key: "storeName"
        },
        {
          title: "合同起止时间",
          width: 220,
          dataIndex: "startDateLg",
          key: "startDateLg",
          scopedSlots: { customRender: "startDateLg" }
        },
        {
          title: "合同状态",
          width: 120,
          dataIndex: "contractStatusName",
          key: "contractStatusName"
        },
        {
          title: "客户状态",
          width: 100,
          dataIndex: "tenantStatusName",
          key: "tenantStatusName"
        },
        {
          title: "是否标准合同",
          dataIndex: "isStandardName",
          width: 120,
          key: "isStandardName"
        },
        {
          title: "操作",
          key: "action",
          width: 150,
          fixed: "right",
          scopedSlots: { customRender: "action" }
        }
      ],
      // 数据
      tableData: [],
      // dataLoading
      dataLoading: false,
      // 日志ID
      actionId: null,
      // 显示日志列表
      visible: false,
      // 日志列表
      actionData: [],
      actionPagination: defaultPagination(() => {}),
      //actioncolumns
      actionColumns: [
        {
          title: "操作人",
          dataIndex: "operator",
          key: "operator"
        },
        {
          title: "操作时间",
          dataIndex: "operationTime",
          key: "operationTime",
          width: "130px"
        },
        {
          title: "操作内容",
          dataIndex: "content",
          key: "content"
        }
      ],
      //是否
      yesOrNo: [
        {
          id: 1,
          name: "是"
        },
        {
          id: 0,
          name: "否"
        }
      ],
      // 合同状态列表
      contractStatusList:[],

      // export excel related
      showExportContractModal: false,
      showExportContractLoading: false,
      exportAvailableFacility: [],
      exportQuery: {},
    };
  },
  methods: {
    moment,
    // 重置页面
    resetQueryParam() {
      this.queryParam = {};
      this.tableData = [];
      this.initTable();
    },
    // 分页
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.pagination.current = val.current;
      this.init();
    },
    initTable() {
      this.tableData = [];
      this.pageSize = 20;
      this.current = 1;
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.init();
    },
    // 初始化
    init() {
      // this.current
      // this.pageSize
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "contract/list",
        method: "POST",
        data: {
          rows: this.pageSize || 20,
          page: this.current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          res.data.rows.forEach(item => {
            item.startDateLg = this.changeTimeStyle(item.startDateLg);
            item.endDateLg = this.changeTimeStyle(item.endDateLg);
            this.getTenantStatus(item);
          });
          this.tableData = res.data.rows || [];
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
          this.dataLoading = false;
        } else {
          this.pageSize = 20;
          this.current = 1;
          this.tableData = [];
          this.$message.error(`${res.data.returnMsg}`);
          const pagination = { ...this.pagination };
          pagination.total = null;
          this.pagination = pagination;
          this.dataLoading = false;
        }
      });
    },
    // 获取字典
    getContractStatus() {
      axios({
        url:   this.$baseUrl + "/code/list",
        method: "GET",
        params: {
          codeType:"contractStatus"
        }
      }).then(res => {
        if (res.data.returnCode == '200') {
          this.contractStatusList = res.data.rows;
        }
      });
    },
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    // 获取状态
    getTenantStatus(val) {
      if (val.tenantStatus == null) {
        return;
      } else {
        this.contractStatusList.forEach((item)=>{
          if(val.tenantStatus == item.code){
            val.tenantStatus = item.name;
          }
        })
      }
      return val.status;
    },
    // 跳转详情页面
    viewDetail(val) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: {
          contractDetailsId: val.id
        }
      });
    },
    // 操作日志
    handleActive(val) {
      this.actionId = val.id;
      this.handleActivecha();
      this.visible = true;
    },
    // 获取日志列表
    handleActivecha(current, pageSize) {
      this.actionData = [];
      axios({
        url:   this.$baseUrl + "contract/listContractLog",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          contractId: this.actionId
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            if (item.operationTime) {
              item.operationTime = moment(item.operationTime).format(
                "YYYY-MM-DD"
              );
            }
          });
          this.actionData = res.data.rows;
          const actionPagination = { ...this.pagination };
          actionPagination.total = res.data.count;
          this.actionPagination = actionPagination;
        }
      });
    },
    // 改变页
    changePageActionSize(val) {
      this.handleActivecha(val.current, val.pageSize);
    },
    // 导出函数
    downloadTemplateWithPost(info, url, name) {
      this.$http.post(url, info, {
        responseType: "blob",
      })
          .then(res => {
            if (res.status === 200) {
              this.$message.success("下载成功！");
              const fileName = name + moment().format('YYYYMMDD') + ".xlsx";
              fileDownload(res.data, fileName);
            } else {
              this.$message.error("下载失败！");
            }
          });
    },

    onChangeExportCity() {
      if (this.exportQuery.cityId === undefined ||
          this.exportQuery.cityId === null) {
        this.exportAvailableFacility = [];
      } else {
        axios({
          url: this.$endpoints.FACILITY_LIST_FACILITY_BY_CITY_AND_BRAND,
          method: "GET",
          params: {
            cityIds: this.getCityIdList(this.exportQuery.cityId).join(","),
          }
        })
            .then(res => {
              if (res.data.success) {
                this.exportAvailableFacility =
                    res.data.rows.map(e => {
                      return {
                        "id": e.id,
                        "name": e.optionText
                      };
                    });
              } else {
                this.$message.error("获取所选城市对应门店失败，错误信息：" + res.data.returnMsg, 3);
                this.exportAvailableFacility = [];
              }
            })
            .catch(error => {
              this.$message.error("获取所选城市对应门店出错，错误信息：" + error.message, 3);
              this.exportAvailableFacility = [];
            });
      }
    },
    onExportContract() {
      this.showExportContractLoading = false;
      this.exportQuery = {};
      this.exportAvailableFacility = [];
      this.showExportContractModal = true;
    },

    onConfirmExportContract() {
      if (this.exportQuery.cityId === undefined ||
          this.exportQuery.cityId === null) {
        this.$message.error("请选择城市", 3);
        return;
      }

      if (this.exportQuery.startDate === undefined ||
          this.exportQuery.startDate === null) {
        this.$message.error("请选择起始日期", 3);
        return;
      }

      if (this.exportQuery.endDate === undefined ||
          this.exportQuery.endDate === null) {
        this.$message.error("请选择结束日期", 3);
        return;
      }

      if (this.exportQuery.endDate < this.exportQuery.startDate) {
        this.$message.error("结束日期早于起始日期", 3);
        return;
      }

      this.showExportContractLoading = true;
      const queryData = {
        cityIdList: this.getCityIdList(this.exportQuery.cityId),
        facilityIdList: this.exportQuery.facilityId ? [this.exportQuery.facilityId] : null,
        startDate: this.exportQuery.startDate.format("YYYY-MM-DD"),
        endDate: this.exportQuery.endDate.format("YYYY-MM-DD"),
      }

      this.downloadTemplateWithPost(
          queryData,
          "contract/exportContracts",
          "合同数据导出"
      );

      setTimeout(() => {
        this.showExportContractLoading = false;
        this.showExportContractModal = false;
      }, 1000);
    },

    onCancelExportContract() {
      this.showExportContractLoading = false;
      this.showExportContractModal = false;
    },
    getCityIdList(cityId) {
      let cityIds = [];
      if (cityId === 0) {
        cityIds = this.userCities.map(e => e.id);
      }
      else {
        cityIds = [cityId];
      }

      return cityIds;
    }
  },
  activated() {
    if (this.$route.query.reset) {
      this.init();
    }
  },
  created() {
    // 初始化城市列表
    this.init();
    this.getContractStatus();
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    ...mapGetters("cities", ["userCities", "userCitiesAll"]),
    isCanViewDetail() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_READ],
        this.authenticatedUser.actions
      );
    },
    isCanViewContractLog() {
      return hasPermissions(
        [OrganizationActions.CONTRACT_EDIT],
        this.authenticatedUser.actions
      );
    }
  }
};
</script>
<style lang="less">
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ml15 {
  margin-left: 1.5em;
}
.btn-item {
  margin-right: 16px;
  margin-bottom: 10px;
}
.mandatory {
  display: inline-block;
  width: 15px;
  color: red;
  margin-right: 3px;
  text-align: right;
}
.optional {
  display: inline-block;
  width: 15px;
  margin-right: 3px;
  text-align: right;
}
.date-picker {
  margin-right: 15px;
}
</style>